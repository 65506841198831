.userListMainDiv{
    .MuiTable-root{
        border: 1px solid white;
    }
    .MuiTableHead-root{
        background-color: black;
    }
    .MuiTableCell-head{
        color: white;
    }
    .MuiTablePagination-root{
        border: 1px solid white;
    }
    .MuiTableBody-root{
        .MuiTableCell-body {
            color:black
        }
    }
    .MuiTableSortLabel-root.MuiTableSortLabel-active {
        color: white;
    }
    .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon{
        color: white;
    }
    .MuiTableSortLabel-root:hover {
        color: white;
    }
    .searchField{
        margin-bottom: 20px;
    }
    .pointer {
        cursor: pointer;
    }
    .MuiTableCell-root {
        border-bottom: 1px solid white;
        border-right: 1px solid white;
    }
    .MuiPaper-rounded {
        border-radius: 0px;
    }
}