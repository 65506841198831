.setting {
    padding: 100px;
    .MuiTextField-root{
        width: 100%;
    }
}
.settingSubmit {
    text-align: right;
    .MuiButton-outlined{
        margin-top:35px
    }
}
.settingPapper {
    padding: 20px;
}

.sLangDropdown{
    width: 100%;
}

@media only screen and (max-width: 500px) { 
    .setting {
        padding: 15px;
    }
}