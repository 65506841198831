.App {
  width: 100%;
  margin: auto;
}

.App-intro {
  font-size: large;
}

.abc {
  .MuiInputBase-root {
    color: 'white' !important
  }
}

.MuiButton-outlinedPrimary {
    color: #c7d100 !important;
    border: 2px solid #c7d100 !important;
}

.MuiButton-outlined {
  color: #c7d100 !important;
  border: 2px solid #c7d100 !important;
}

.MuiButton-outlined.Mui-disabled {
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.MuiButton-root.Mui-disabled {
  color: rgba(255, 255, 255, 0.3) !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #c7d100 !important;
}
.MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #c7d100 inset !important;
}

.MuiTypography-colorPrimary {
  color: #c7d100 !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: #c7d100 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #c7d100 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

