.sensorListMainDiv{
    margin:50px;

    .paperM{
        margin-bottom:40px;
        padding:10px;
    }

    .table{
        border: 1px solid white;
    }
    .MuiTableBody-root {
        border-bottom: 2px solid white;
        border-top: 2px solid white;
    }
    .MuiTableHead-root{
        background-color: black;
    }
    .MuiTable-root{
        border: 1px solid white;
    }
    .MuiTablePagination-root{
        border: 1px solid white;
    }
    .addSensors{
        margin-top: 50px;
        text-align: center;
        .MuiSvgIcon-root{
            font-size: 100px;
        }
    }
}

@media only screen and (max-width: 500px) { 
    .sensorListMainDiv{
        margin:15px;
    }
}


