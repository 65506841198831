.deviceMainDiv{
    margin:50px;
    .deviceListPaper{
        text-align: center;
    }
}

@media only screen and (max-width: 500px) { 
    .deviceMainDiv{
        margin:15px
    }
}