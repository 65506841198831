.chart-container {
  position: relative;
  height: 20px;
  width: 100%;
}
.rdrDateRangePickerWrapper {
  width: 100%;
}
.react-datepicker-ignore-onclickoutside {
  width: 100%;
  padding: 18px 10px !important;
  border-radius: 4px;
}
.date-label-light {
  margin-top: -17px;
  margin-bottom: 6px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.date-label-dark {
  margin-top: -17px;
  margin-bottom: 6px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

.light-input {
  width: 100%;
  padding: 18px 10px !important;
  border-radius: 4px;
  border: 1px solid #80808080;
  font-size: 14px;
  &:focus {
    outline: none;
  }
  &:hover {
    border-color: #c7d100;
  }
}
.dark-input {
  color: white;
  background: black;
}

.react-datepicker__day--selected {
  background-color: #c7d100 !important;
  color: #fff;
}
.react-datepicker__day--keyboard-selected {
  background-color: #c7d100 !important;
  color: #fff;
}

.dMargin {
  margin: 50px;
}
.sensorDropdown {
  float: left;
  width: 100%;
}
.sensorSetting {
  text-align: left;
}
.sensorSetting {
  .MuiOutlinedInput-input {
    padding: 4.5px 14px;
  }
}
.bgColor {
  background: #f2efc2 !important;
}

.noFound {
  padding: 30px;
}

.legend {
  .MuiList-root {
    margin: auto !important;
    display: flex;
    flex-direction: row;
  }

  .MuiListItem-root {
    flex-direction: column;
  }
}

.settingDropdown {
  width: 100px;
}

.settingName {
  word-break: break-all;
}

.settingCheckbox {
  padding: 5px !important;
}

.MuiCircularProgress-colorPrimary {
  color: #c7d100 !important;
}

@media only screen and (max-width: 500px) {
  // .sensorDropdown{
  //   width: 220px;
  // }
  .dashboard {
    .MuiPaper-rounded {
      border: 2px solid #333;
      padding: 20px;
    }
  }
  .dMargin {
    margin: 15px;
  }
}
