.addSensorDiv{
    margin: 50px;
    .scanIcon {
       cursor: pointer;
    }
    .addSensorPaper{
        text-align: center;
    }
    .paperM{
        margin-bottom:40px;
        padding:10px;
    }
    .addSensorPaper{
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (min-width: 700px){
    .previewStyle{
        height: 240;
        width: 320;
    }
}