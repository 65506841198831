h1 {
  font-size: 2em;
}

.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.titleOfProject {
  margin-top: 15px;
  margin-left: 15px;
  text-decoration: none !important;
}

.translaterIcon {
  margin-right: 8px;
}

.product-id {
  font-size: 0.8em;
  margin: 1em 0;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.product-edit-icon {
  opacity: 0.7;
  position: absolute;
  right: 2.3rem;
  top: 0.4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.logoutBtn {
  margin-top: 5px;
}

.logo {
  // width:100px;
  height:50px;
}

.navbar,
.navbar-end,
.navbar-menu,
.navbar-start {
  align-items: stretch !important;
  display: flex !important;
  background-color: #d6e0ef;
  height: 50px;
}

.switchMode {
  margin-left: 0px !important;
}

.navbar-menu {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: flex !important;
  text-align: right;
}

.navbar-end {
  justify-content: flex-end !important;
  margin-left: auto !important;
}

.features {
  padding-bottom: 3em;
}

body {
  visibility: visible !important;
}

.auth form {
  margin-top: 1em;
  /* max-width: 50%; */
}

.buttons {
  margin-left: 1em;
  margin-top: 7px;
  margin-right: 15px;
}
.menu {
  width: 160px;
}

.menuPaper {
  position: relative;
}

.languageInput {
  .MuiInputBase-root {
    color: white;
  }
}
.languageDropdown {
  .MuiOutlinedInput-input {
    padding: 11.5px 14px;
  }
}

.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: black !important;
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .logo {
    font-size: 1.125rem !important;
  }
  .languageDropdown {
    .MuiOutlinedInput-input {
      padding: 5.5px 14px;
    }
  }
}
