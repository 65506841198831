.userListMainDiv{
    margin:50px;

    .table{
        border: 1px solid white;
    }
    
    .MuiTableBody-root {
        border-bottom: 2px solid white;
        border-top: 2px solid white;
    }
}

@media only screen and (max-width: 500px) { 
    .userListMainDiv{
        margin:15px;
    }
}

