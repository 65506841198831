.section {
    // width: 50%;
    margin: auto;
    margin-top: 10%;
  }
  
  .avtarDiv,
  .avtarDivRegister {
    width: 5%;
    margin: auto;
    color: blue;
  }
  
  .sectionogLogin {
    width: 50%;
    margin: auto;
    margin-top: 5%;
  }
  
  .loginForm {
    width: 50%;
    margin: auto;
  }

  .signInFormMargin{
    max-width: 350px;
    margin: auto;
  }

  .signInContainer{
    margin: 15px;
  }

  .loginRouteLink {
    margin-top: 20px;
    cursor: pointer;
    text-align: right;
  }
  
  .errorMsg {
    text-align: center;
    margin: auto;
    // margin-top: 20px;
    color: red;
  }
  
  .registerBtn {
    padding-top: 30px;
  }
  
  .lodinHeader {
    width: 50%;
    margin: auto;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  #succesRegistarionPage {
    width: 60%;
    margin: auto;
    margin-top: 10%;
    text-align: center !important;
  }
  
  .loginBtnOfWelcome {
    padding-top: 40px;
    text-align: center;
    width: 30%;
    margin: auto;
  }
  
  #backtoLoginPage {
    width: 30%;
    margin: auto;
    margin-top: 17%;
    text-align: center;
  }
  
  .loginBackHeader {
    margin-bottom: 30px;
    font-size: 30px;
  }

  .MuiAvatar-root {
    margin: auto;
  }
 
  @media only screen and (max-width: 600px) {
    .section,
    .sectionogLogin {
      // width: 80%;
      margin: auto;
    }
    .loginForm {
      width: 100%;
    }
    .avtarDiv {
      width: 10%;
      margin: auto;
      margin-top: 10%;
    }
  
    .avtarDivRegister {
      width: 10%;
      margin: auto;
      margin-top: 10%;
    }

    #succesRegistarionPage {
      width: 80%;
      margin: auto;
      margin-top: 30%;
    }
  
    .loginBtnOfWelcome {
      width: 70%;
      margin: auto;
    }

    #backtoLoginPage {
      width: 80%;
      margin-top: 30%;
      text-align: center;
    }
  }