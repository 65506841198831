.sensorListMainDiv {
    .MuiTableBody-root .MuiTableCell-body {
        color:black;
    }
    .MuiTableBody-root{
        background-color: #CBCBCB;
    }
    .MuiTableCell-head{
        color: white;
    }
    .tableIcon{
        .MuiSvgIcon-root {
            color:black
        }
    }
    .pointer {
        cursor: pointer;
    }
    .MuiTableCell-root {
        border-bottom: 1px solid white;
        border-right: 1px solid white;
    }
    .MuiPaper-rounded {
        border-radius: 0px;
    }
}
// .MuiDialogActions-root {
//     justify-content: unset !important;
// }
.dialog{
    .MuiButton-outlined {
        color:#CBCBCB !important;
        border: 2px solid #CBCBCB !important;
    }
    .MuiButton-outlinedPrimary {
        color: #CBCBCB !important;
        border: 2px solid #CBCBCB !important;
    }
     .MuiButton-outlined:hover {
        color: #c7d100 !important;
        border: 2px solid #c7d100 !important;
    }
    .MuiButton-outlinedPrimary:hover {
        color: #c7d100 !important;
        border: 2px solid #c7d100 !important;
    }
    .cancelBtn{
        .MuiButton-outlinedPrimary {
            color: #c7d100 !important;
            border: 2px solid #c7d100 !important;
        }
    }
    .deleteBtn{
        margin: 0 auto;
        float: left;
        margin-left: 0px;
    }
}